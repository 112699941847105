.page__id-verification {
  .verification-panel {
    img {
      max-width: 100%;
    }
    .card.accent {
      border-top-width: 4px;
      border-top-style: solid;
    }
    .image-preview {
      margin-bottom: 1rem;
      max-width: 20rem;
      img {
        display: block;
        max-height: 10rem;
      }
    }
  }
  .action-row {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    margin-bottom: 12px;
    .btn-primary {
      min-width: 8rem;
      margin-left: 1rem;
    }
    .btn-link {
      padding-left: 0;
      padding-right: 0;
      text-decoration: underline;
      align-self: center;
    }
  }
  .camera-outer-wrapper {
    margin-bottom: 15px;
    text-align: center;
    border-radius: 0.3rem;
  }
  .camera-wrapper {
    position: relative;
    width: 100%;
    height: 100%
  }

  .camera-video {
    width: 100%;
  }

  .canvas-video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .camera-btn {
    margin: 10px;
  }

  .camera-flash {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity :1;
    transition:opacity .9s ease-out;
  }

  .camera-flash.do-transition {
    opacity: 0;
    background: white;
  }
}
