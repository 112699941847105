.page__account-settings {
  .form-group {
    margin-bottom: 1.5rem;
  }

  h6, .h6 {
    margin-bottom: .25rem;
  }

  .btn-link {
    line-height: 1.2;
    border: none;
    padding: 0;
    display: inline-block;
  }

  .jump-nav-sm {
    top: 1rem;
  }

  .jump-nav {

    li {
      margin-bottom: .5rem;

      a {
        text-decoration: underline;
      }
    }
  }


  .custom-switch {
    padding: 0;
    max-width: 500px;

    .custom-control-label {
      left: 2.25rem;
      line-height: 1.6rem;
    }
  }
  .grayed-out{
    opacity: 0.6; /* Real browsers */
    filter: alpha(opacity = 60); /* MSIE */
  }
}
