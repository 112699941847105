$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

@import "~@edx/brand/paragon/fonts";
@import "~@edx/brand/paragon/variables";
@import "~@openedx/paragon/scss/core/core";
@import "~@edx/brand/paragon/overrides";
@import "~@edx/frontend-component-header/dist/index";
@import "~@edx/frontend-component-footer/dist/footer";

@import "./account-settings/style";
@import "./id-verification/id-verification";

.word-break-all {
  word-break: break-all !important;
}

// TODO: Update edx-bootstrap theme to incorporate these edits.
.btn, a.btn {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.btn-link {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  & > main {
    flex-grow: 1;
  }
}

.checkboxOption {
  input:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }

  label {
    font-weight: normal;
  }
}

.notification-heading {
  line-height: 36px;
  font-weight: 700;
  font-size: 32px;
}

.px-2\.25 {
  padding-left: 0.625rem;
}

.notification-sub-heading {
  font-size: 14px;
  line-height: 28px;
}

.text-decoration-underline {
  text-decoration: underline;
}

.pgn__hyperlink__external-icon{
  margin-left: 4px;
}

.notification-preferences {
  input[type="checkbox"] {
    margin-right: 0;
  }

  .header-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    height: 28px;
    color: #707070;
  }

  .preference-app {
    font-size: 18px;
  }

  .column-padding {
    padding-left: 32px;
    padding-right: 32px;
  }

  .notification-course-title {
    line-height: 28px;
    font-weight: 700;
    font-size: 18px;
  }

  .font-size-14 {
    font-size: 14px !important;
  }

  .cadence-button {
    width: 134px;
    height: 36px;
    font-weight: 500;
  }

  .channel-column:last-child {
    border-right: 0px !important;
  }

  .email-channel {
    width: 250px !important;
  }

  .dropdown-item:active,
  .dropdown-item:focus,
  .btn-tertiary:not(:disabled):not(.disabled).active {
    background-color: $light-300 !important;
  }


  .line-height-36 {
    line-height: 36px;
  }

  .h-4\.5 {
    height: 36px;
  }

  .course-dropdown{
    #course-dropdown-btn {
      width: 100%;
      font-size: 14px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      border: 1px solid $light-500 !important;
    }

    .dropdown-item {
      font-size: 14px !important;
    }
  }
}

.usabilla_live_button_container {
  right: 0 !important;
}

@media (min-width: 768.98px) {
  .usabilla_live_button_container {
    right: -22px !important;
    top: 50% !important;
    transform: rotate(270deg) !important;
  }
}

@media screen and (max-width: 425px) {
   .column-padding{
    padding-left: 16px;
    padding-right: 16px;
  }

  .margin-bottom-32{
    margin-bottom: 32px !important;
  }
}

